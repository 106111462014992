
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref, Watch } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project LetsRevolution
	 */
	@Component
	export default class PagePrivacyPolicy extends PageBase {
		/**
		 * @type HTMLHeadingElement
		 */
		@Ref('cookies')
		readonly cookies!: HTMLHeadingElement;

		/**
		 * @return void
		 */
		@Watch('$route', { deep: true })
		@mounted
		protected scrollToCookies(): void {
			if (this.$route.hash === '#cookies') {
				this.cookies.scrollIntoView();
			}
		}
	}
