
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Common/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Module
	 * @project Lets! Revolution!
	 */
	@Component
	export default class ViewModuleArrivingOnConsoles extends ViewBase {
		// Not implemented
	}
