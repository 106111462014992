/**
 * @class Constants
 * @package Common
 * @project LetsRevolution
 */
export default {
	/**
	 * @type string
	 */
	GOOGLE_ANALYTICS_ID: process.env.VUE_APP_GOOGLE_ANALYTICS_ID || 'G-PGW3RT8QGB',

	/**
	 * Determine if this is being accessed via mobile device
	 *
	 * @type boolean
	 */
	IS_MOBILE: 'ontouchstart' in window,

	/**
	 * Determine if we're on the production environment
	 *
	 * @type boolean
	 */
	IS_PRODUCTION: location.href.indexOf(process.env.VUE_APP_PRODUCTION_URL || 'letsrevolution.com') > -1,

	/**
	 * Determine if we're on the staging environment
	 *
	 * @type boolean
	 */
	IS_STAGING: location.href.indexOf(process.env.VUE_APP_STAGING_URL || 'vercel.com') > -1,

	/**
	 * @type string
	 */
	LINK_TO_DISCORD: process.env.VUE_APP_LINK_TO_DISCORD || 'https://www.letsrevolution.com/discord',

	/**
	 * @type string
	 */
	LINK_TO_ESRB: process.env.VUE_APP_LINK_TO_ESRB || 'https://www.esrb.org',

	/**
	 * @type string
	 */
	LINK_TO_HUMBLE: process.env.VUE_APP_LINK_TO_HUMBLE || 'https://www.humblebundle.com/store/lets-revolution',

	/**
	 * @type string
	 */
	LINK_TO_KEYMAILER: process.env.VUE_APP_LINK_TO_KEYMAILER || 'https://www.keymailer.co/g/games/9abeccc',

	/**
	 * @type string
	 */
	LINK_TO_PLAYSTATION: process.env.VUE_APP_LINK_TO_PLAYSTATION || 'https://store.playstation.com/en-us/concept/10009036',

	/**
	 * @type string
	 */
	LINK_TO_PRESS_KIT: process.env.VUE_APP_LINK_TO_PRESS_KIT || 'https://www.dropbox.com/sh/3t5u5ntmphrkd1a/AACBUVMkfDhkfNItbGff4ICaa?dl=0',

	/**
	 * @type string
	 */
	LINK_TO_SOCIAL_INSTAGRAM: process.env.VUE_APP_LINK_TO_SOCIAL_INSTAGRAM || 'https://www.instagram.com/letsrevolution',

	/**
	 * @type string
	 */
	LINK_TO_SOCIAL_TIKTOK: process.env.VUE_APP_LINK_TO_SOCIAL_TIKTOK || 'https://www.tiktok.com/@letsrevolution',

	/**
	 * @type string
	 */
	LINK_TO_SOCIAL_TWITTER: process.env.VUE_APP_LINK_TO_SOCIAL_TWITTER || 'https://twitter.com/king_of_beebom',

	/**
	 * @type string
	 */
	LINK_TO_SOCIAL_YOUTUBE: process.env.VUE_APP_LINK_TO_SOCIAL_YOUTUBE || 'https://www.youtube.com/@letsrevolution',

	/**
	 * @type string
	 */
	LINK_TO_STEAM: process.env.VUE_APP_LINK_TO_STEAM || 'https://store.steampowered.com/app/2111090/Lets_Revolution/',

	/**
	 * @type string
	 */
	LINK_TO_SUBSCRIBE: process.env.VUE_APP_LINK_TO_SUBSCRIBE || 'https://mailchi.mp/letsrevolution.com/subscribe',

	/**
	 * @type string
	 */
	LINK_TO_SWITCH: process.env.VUE_APP_LINK_TO_SWITCH || 'https://www.nintendo.com/us/store/products/lets-revolution-switch/',

	/**
	 * @type string
	 */
	LINK_TO_TRAILER: process.env.VUE_APP_LINK_TO_TRAILER || 'https://www.youtube.com/embed/4eNv62ACiTk',

	/**
	 * @type string
	 */
	LINK_TO_WHITERABBIT: process.env.VUE_APP_WHITERABBIT_URL || 'https://lets-revolution-git-orphan-white-rabbit-buck-co.vercel.app',

	/**
	 * @type string
	 */
	LINK_TO_XBOX: process.env.VUE_APP_LINK_TO_XBOX || 'https://www.xbox.com/en-US/games/store/lets-revolution/9P3BDVBX93J1',

	/**
	 * @type string
	 */
	LOGIN_REDIRECT_PATH: '/',

	/**
	 * @type string
	 */
	MAILCHIMP_DC: process.env.VUE_APP_MAILCHIMP_DC || 'us5',

	/**
	 * @type string
	 */
	MAILCHIMP_LIST_ID: process.env.VUE_APP_MAILCHIMP_LIST_ID || '009157e9f0',

	/**
	 * @type string
	 */
	MAILCHIMP_USER_ID: process.env.VUE_APP_MAILCHIMP_USER_ID || '657f10a28a489f493b998d915',

	/**
	 * @type string
	 */
	META_PIXEL_ID: process.env.VUE_APP_META_PIXEL_ID || '1181907652602034',

	/**
	 * @type string
	 */
	REDDIT_PIXEL_ID: process.env.VUE_APP_REDDIT_PIXEL_ID || 't2_8jfy6s9bf',

	/**
	 * Don't go under 3s
	 *
	 * @type number
	 */
	SPEECH_ROTATION_DURATION: 1000 * 5,

	/**
	 * @type string
	 */
	STORAGE_KEY: 'lets-revolution-microsite',
};
