import State from './state';

/**
 * @class Mutations
 * @package Store
 * @project LetsRevolution
 */
export default {
	/**
	 * @param State state
	 * @param IAuthResponse response
	 * @return void
	 */
	authenticate(state: typeof State, response: IAuthResponse | boolean): void {
		if (response === false) {
			state.isLoggedIn = false;
			state.token = undefined;
			state.user = undefined;
		}
		else if (typeof response != 'boolean') {
			state.isLoggedIn = true;
			state.token = response.auth.token;
			state.user = response.user;
		}
	},

	/**
	 * @param State state
	 * @param boolean accept
	 * @return void
	 */
	cookies(state: typeof State, accept: boolean): void {
		state.cookies = accept;
	},
};
