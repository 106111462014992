
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewDialog extends ViewBase {
		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickClose(e: MouseEvent): Promise<void> {
			e.preventDefault();

			this.$emit('close');
		}

		// endregion: Event Handlers
	}
