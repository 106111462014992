import Constants from '@/Common/Constants';
import PageError404 from '@/Page/Error404.vue';
import PageFaq from '@/Page/Faq.vue';
import PageHome from '@/Page/Home.vue';
import PagePrivacyPolicy from '@/Page/PrivacyPolicy.vue';
import PagePrototype from '@/Page/Prototype.vue';
import PageStyleguide from '@/Page/Styleguide.vue';
import PageTermsConditions from '@/Page/TermsConditions.vue';
import Store from '@/Store';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// Attach VueRouter
// -----------------------------------------------------------------------------

Vue.use(VueRouter);

// Define Routing
// -----------------------------------------------------------------------------

const unauthenticatedRoutes: string[] = ['PageHome', 'PageLogout'];

const routes: Array<RouteConfig> = [
	{
		component: PageHome,
		name: 'PageHome',
		path: '/',
	},
	{
		component: PageFaq,
		name: 'PageFaq',
		path: '/faq',
	},
	{
		component: PagePrivacyPolicy,
		name: 'PagePrivacyPolicy',
		path: '/privacy',
	},
	{
		component: PageStyleguide,
		name: 'PageStyleguide',
		path: '/styleguide',
	},
	{
		component: PageTermsConditions,
		name: 'PageTermsConditions',
		path: '/terms',
	},
	{
		component: PagePrototype,
		name: 'PagePrototype',
		path: '/prototype',
	},
	{
		component: PageError404,
		name: 'PageError404',
		path: '*',
	},
];

// Setup Router
// -----------------------------------------------------------------------------

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes: routes,
});

// Middleware hooks
// ---------------------------------------------------------------------------

// Paths are represented like: /desktop even if it's using hash mode
router.beforeEach((to, from, next) => {
	const isAuthenticated: boolean = Store.getters?.authenticated;
	const toName: string = to.name || '';

	// Scroll to top
	window.scrollTo(0, 0);

	// Authenticated to unacceptable page, redirect home
	next();
});

export default router;
