
	import { Component, Ref } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';
	import ViewSprite from '@/View/Sprite.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewUiSpeechBubble extends ViewBase {
		/**
		 * @type ViewSprite
		 */
		@Ref('sprite')
		readonly sprite!: ViewSprite;

		/**
		 * @type boolean
		 */
		protected isActive: boolean = false;

		/**
		 * @type number
		 */
		protected phraseIndex: number = 0;

		/**
		 * @type string[]
		 */
		protected phrases: string[] = [
			'Whatcha waitin\' for bub?',
			'You gonna subscribe or what?',
			'It\'s a really good idea.',
			'There\'s some really good information in there.',
		];

		/**
		 * @return void
		 */
		public animateIn(): void {
			this.sprite?.animateIn();

			setTimeout(() => this.isActive = true, 500);
		}

		/**
		 * @return void
		 */
		public animateOut(): void {
			this.isActive = false;
			this.sprite?.animateOut();
		}

		/**
		 * @return void
		 */
		public animateInOut(): void {
			this.animateOut();

			setTimeout(() => {
				// Update phrase index
				this.phraseIndex = (this.phraseIndex + 1) % this.phrases.length;

				// Animate
				this.animateIn();
			}, 1000);
		}
	}
