
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewCharacter extends ViewBase {
		/**
		 * @return string
		 */
		public get imageUrl(): string {
			let images = require.context('@/Asset/image/character', false, /\.webp$/);
			return images(`./${this.type}`);
		}

		/**
		 * @type string
		 */
		@Prop({
			default: 'phlox.webp',
		})
		public type!: string;
	}
