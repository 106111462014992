
	import Lang from '@/Lib/Lang';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Common/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project Lets! Revolution!
	 */
	@Component
	export default class ViewQuotes extends ViewBase {
		/**
		 * @return any
		 */
		public get quotes(): any {
			return Lang.get('quotes');
		}

		/**
		 * @return number
		 */
		protected get maxIndex(): number {
			return this.quotes.length - 1;
		}

		/**
		 * @return void
		 */
		protected currentIndex: number = 0;

		/**
		 * @return string
		 */
		protected direction: string = 'next';

		/**
		 * mk: Fix this logic. It's overly verbose
		 * @param number index
		 * @return string
		 */
		protected getStateDirection(index: number): string {
			if (index === this.currentIndex) {
				return 'state-active';
			}

			const isGoingForward = this.direction === 'next';
			const isGoingBackward = this.direction === 'previous';
			const fromFirstToLast = this.currentIndex === 0 && index === this.maxIndex;
			const fromLastToFirst = this.currentIndex === this.maxIndex && index === 0;

			if (isGoingForward) {
				if (this.currentIndex === 0 && index === this.maxIndex) {
					return 'state-direction-left';
				}
				if (index > this.currentIndex || fromLastToFirst) {
					return 'state-direction-right';
				}
				if (index < this.currentIndex || fromFirstToLast) {
					return 'state-direction-left';
				}
			}

			if (isGoingBackward) {
				if (this.currentIndex === this.maxIndex && index === 0) {
					return 'state-direction-right';
				}
				if (index < this.currentIndex || fromFirstToLast) {
					return 'state-direction-left';
				}
				if (index > this.currentIndex || fromLastToFirst) {
					return 'state-direction-right';
				}
			}

			// Fallback state, in case none of the above conditions are met
			return 'state-undefined';
		}

		/**
		 * @return void
		 */
		protected next(): void {
			this.direction = 'next';
			this.currentIndex = this.currentIndex >= this.maxIndex ? 0 : this.currentIndex + 1;
		}

		/**
		 * @return void
		 */
		protected previous(): void {
			this.direction = 'previous';
			this.currentIndex = this.currentIndex <= 0 ? this.maxIndex : this.currentIndex - 1;
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickNext(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.next();
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickPrevious(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.previous();
		}
	}
