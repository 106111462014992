
/**
 * @author Matt Kenefick<matt.kenefick@buck.co>
 * @package Lib
 * @project Lets Revolution
 */
export default class Preloader {
	/**
	 * @type string[]
	 */
	private images: string[];

	/**
	 * @type HTMLImageElement[]
	 */
	private loadedImages: HTMLImageElement[];

	/**
	 * @param string[] images
	 * @constructor
	 */
	constructor(images: string[] = []) {
	  this.images = images;
	  this.loadedImages = [];
	}

	/**
	 * @param string[] images
	 * @return Preloader
	 */
	public setImages(images: string[]): Preloader {
		this.images = images;
		return this;
	}

	/**
	 * @return Promise<void>
	 */
	public preload(): Promise<any> {
	  const promises = this.images.map((src) => {
			const image = new Image();
			this.loadedImages.push(image);

			// Add to head
			// <link rel="preload" href="/images/bg-min.png" as="image">
			const link = document.createElement('link');
			link.as = 'image';
			link.href = src;
			link.rel = 'preload';
			document.head.appendChild(link);

			return new Promise<void>((resolve, reject) => {
				image.onload = () => {
					resolve();
				};

				image.onerror = () => {
					reject(new Error(`Failed to load image: ${src}`));
				};

				image.src = src;
			});
	  });

	  return Promise.all(promises);
	}

	/**
	 * @return HTMLImageElement[]
	 */
	public getLoadedImages(): HTMLImageElement[] {
		return this.loadedImages;
	}

	/**
	 * @param string url
	 * @return HTMLImageElement | null
	 */
	public getByUrl(url: string): HTMLImageElement | null {
		for (const image of this.loadedImages) {
			if (image.src === url) {
				return image;
			}
		}

		return null;
	}

	/**
	 * @param string url
	 * @return HTMLImageElement | null
	 */
	public getByFuzzyUrl(url: string): HTMLImageElement | null {
		for (const image of this.loadedImages) {
			if (image.src.indexOf(url) > -1) {
				return image;
			}
		}

		return null;
	}
}
