import { createDecorator } from 'vue-class-component';
import { WatchOptions } from 'vue';

// Helpers
// ---------------------------------------------------------------------------

/**
 * @param string methodName
 * @return Function
 */
function methodDecorator(methodName: string) {
	return createDecorator((options: any, key: number | string): void => {
		const original = options[methodName];
		const method = options.methods[key];

		options[methodName] = function() {
			original && original.call(this);
			method && method.call(this);
		};
	});
}

// Export
// ---------------------------------------------------------------------------

export const beforeCreate = methodDecorator('beforeCreate');
export const created = methodDecorator('created');
export const beforeDestroy = methodDecorator('beforeDestroy');
export const beforeMount = methodDecorator('beforeMount');
export const mounted = methodDecorator('mounted');
export const beforeUpdate = methodDecorator('beforeUpdate');
export const updated = methodDecorator('updated');
export const beforeUnmount = methodDecorator('beforeUnmount');
export const unmounted = methodDecorator('unmounted');
export const errorCaptured = methodDecorator('errorCaptured');
export const renderTracked = methodDecorator('renderTracked');
export const renderTriggered = methodDecorator('renderTriggered');
export const activated = methodDecorator('activated');
export const deactivated = methodDecorator('deactivated');
export const serverPrefetch = methodDecorator('serverPrefetch');

// Special
// ---------------------------------------------------------------------------

/**
 * decorator of a watch function
 * @param  path the path or the expression to observe
 * @param  watchOptions
 */
export const routeChange = (function() {
	const path: string = '$route';
	const watchOptions: WatchOptions = { immediate: true };

	return createDecorator((componentOptions, handler) => {
		componentOptions.watch ||= Object.create(null);
		const watch: any = componentOptions.watch;

		if (typeof watch[path] === 'object' && !Array.isArray(watch[path])) {
			watch[path] = [watch[path]];
		}
		else if (typeof watch[path] === 'undefined') {
			watch[path] = [];
		}

		watch[path].push({
			handler,
			...watchOptions,
		});
	});
})();
