
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Modal
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewModalLogin extends ViewBase {
		/**
		 * @type string
		 */
		protected email: string = '';

		/**
		 * @type string
		 */
		protected password: string = '';


		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param Event e
		 * @return Promise<void>
		 */
		protected async Handle_OnSubmit(e: Event): Promise<void> {
			e.preventDefault();

			// Request auth from Style Engine
			// const response = await Auth.login(this.email, this.password);
			// const json = await response.json();

			// // Throw error
			// if (json.error) {
			// 	alert(json.error);
			// }

			// // Login
			// else if (json.auth && json.user) {
			// 	this.$store.dispatch('login', json);

			// 	// @ts-ignore
			// 	this.$parent?.close();
			// }
		}

		// endregion: Event Handlers
	}
