import './Theme';
import App from './App.vue';
import Constants from './Common/Constants';
import RegisterComponents from './Lib/RegisterComponents';
import Router from './Router';
import Store from './Store';
import Vue from 'vue';

/**
 * Disable Vue stuff
 */
Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.config.silent = true;

/**
 * Disable console logs if Constants.IS_PRODUCTION
 */
if (Constants.IS_PRODUCTION) {
	console.error = () => { /* Do nothing */ };
	console.info = () => { /* Do nothing */ };
	console.log = () => { /* Do nothing */ };
	console.warn = () => { /* Do nothing */ };
}

/**
 * Register components
 */
Vue.use(RegisterComponents, Constants);

/**
 * Launch frontend application
 */
(async function () {
	new Vue({
		render: (h) => h(App),
		router: Router,
		store: Store,
	}).$mount('#app');
})();
