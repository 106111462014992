
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';
	import ViewButtonCompany from '@/View/Button/Company.vue';
	import ViewButtonIcon from '@/View/Button/Icon.vue';
	import ViewRibbon from '@/View/Ribbon.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component({
		components: {
			ViewButtonCompany,
			ViewButtonIcon,
			ViewRibbon,
		},
	})
	export default class ViewExample extends ViewBase {
		// Not implemented
	}
