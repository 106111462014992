
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project Lets Revolution
	 */
	@Component
	export default class ViewImage extends ViewBase {
		/**
		 * @type HTMLElement
		 */
		@Ref('imageWrapper')
		readonly imageWrapper!: HTMLElement;

		/**
		 * @type string
		 */
		@Prop({ default: 'Image' })
		public alt!: string;

		/**
		 * @type string
		 */
		@Prop()
		public src!: string;

		/**
		 * @return boolean
		 */
		protected isExpanded: boolean = false;

		/**
		 * @return void
		 */
		public enlarge(): void {
			const element: HTMLElement = this.$el as HTMLElement;

			// Save dimensions of this image
			element.style.height = element.offsetHeight + 'px';
			element.style.width = element.offsetWidth + 'px';

			// Get position of element considering scroll and offset
			const position = element.getBoundingClientRect();

			// Set the position to be fixed and left and top to be the current position
			this.imageWrapper.style.height = element.style.height;
			this.imageWrapper.style.left = `${position.x}px`;
			this.imageWrapper.style.position = 'fixed';
			this.imageWrapper.style.top = `${position.y}px`;
			this.imageWrapper.style.width = element.style.width;
		}

		/**
		 * @return void
		 */
		public shrink(): void {
			const element: HTMLElement = this.$el as HTMLElement;

			// Get position of element considering scroll and offset
			const position = element.getBoundingClientRect();

			// Set the position to be fixed and left and top to be the current position
			this.imageWrapper.style.left = `${position.x}px`;
			this.imageWrapper.style.top = `${position.y}px`;

			setTimeout(() => {
				element.style.removeProperty('height');
				element.style.removeProperty('width');

				this.imageWrapper.style.removeProperty('height');
				this.imageWrapper.style.removeProperty('left');
				this.imageWrapper.style.removeProperty('position');
				this.imageWrapper.style.removeProperty('top');
				this.imageWrapper.style.removeProperty('transition');
				this.imageWrapper.style.removeProperty('width');
			}, 260);
		}

		/**
		 * @return void
		 */
		public toggle(): void {
			if (this.isExpanded) {
				this.shrink();
			}
			else {
				this.enlarge();
			}

			// Toggle expanded state
			setTimeout(() => {
				this.isExpanded = !this.isExpanded;
			}, 1);
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: MouseEvent): Promise<void> {
			this.toggle();
		}

		// endregion: Event Handlers
	}
