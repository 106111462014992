
	import { beforeDestroy, mounted } from '@/Common/Decorators';
	import { Component, Ref } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import Interval from '@/Lib/Interval';
	import ViewBase from '@/View/Base.vue';
	import ViewUiSpeechBubble from '@/View/Ui/SpeechBubble.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewUiSpeechBubbleWithCharacter extends ViewBase {
		/**
		 * @type ViewUiSpeechBubble
		 */
		@Ref('speechBubble')
		readonly speechBubble!: ViewUiSpeechBubble;

		/**
		 * @return void
		 */
		@mounted
		public startSpeechRotation(): void {
			Interval.add(() => {
				this.speechBubble.animateInOut();
			}, Math.max(1000 * 3, Constants.SPEECH_ROTATION_DURATION), 'speechRotation');
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public stopSpeechRotation(): void {
			Interval.remove('speechRotation');
		}

		/**
		 * @return void
		 */
		public animateIn(): void {
			this.speechBubble.animateIn();
		}

		/**
		 * @return void
		 */
		public animateOut(): void {
			this.speechBubble.animateOut();
		}
	}
