
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewUiTooltip extends ViewBase {
		/**
		 * @type string
		 */
		@Prop({ default: 'Lorem ipsum' })
		public description!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'Title' })
		public title!: string;

		/**
		 * @type string
		 */
		@Prop({ default: '' })
		public type!: string;
	}
