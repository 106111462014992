
	import { beforeDestroy, created, mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import { embedGoogleAnalytics, embedMetaPixel, embedRedditPixel } from '@/Lib/Analytics';
	import Constants from '@/Common/Constants';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewDialogCookie extends ViewBase {
		/**
		 * @type boolean
		 */
		public get showCookieDialog(): boolean {
			return typeof this.$store.state.cookies !== 'boolean';
		}

		/**
		 * @return void
		 */
		@mounted
		public embedAnalyticsIfWeHaveCookies(): void {
			if (this.$store.state.cookies) {
				embedGoogleAnalytics(Constants.GOOGLE_ANALYTICS_ID);
				embedMetaPixel(Constants.META_PIXEL_ID);
				embedRedditPixel(Constants.REDDIT_PIXEL_ID);
			}
		}

		/**
		 * @return void
		 */
		public cookiesAccept(): void {
			this.$store.dispatch('cookiesAccept');

			// Embed again
			this.embedAnalyticsIfWeHaveCookies();
		}

		/**
		 * @return void
		 */
		public cookiesDeny(): void {
			this.$store.dispatch('cookiesDeny');
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickAcceptCookies(e: MouseEvent): Promise<void> {
			e.preventDefault();

			this.cookiesAccept();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnClickDenyCookies(): Promise<void> {
			this.cookiesDeny();
		}

		// endregion: Event Handlers
	}
