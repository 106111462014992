
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref, Watch } from 'vue-property-decorator';
	import App from '@/App.vue';
	import Interval from '@/Lib/Interval';
	import Preloader from '@/Lib/Preloader';
	import ViewBase from '@/View/Base.vue';

	/**
	 * mk: This class has some nonsense code in it because of how many
	 * weird issues I ran into today. Some are ghosts, but many are
	 * Safari. So I'm just trying to get this thing locked right now.
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewUiLogo extends ViewBase {
		/**
		 * @type HTMLCanvasElement
		 */
		@Ref('canvas')
		readonly canvas!: HTMLCanvasElement;

		/**
		 * @type CanvasRenderingContext2D
		 */
		private context!: CanvasRenderingContext2D;

		/**
		 * @type number
		 */
		private frameRate: number = 70;

		/**
		 * @type number
		 */
		private frameMax: number = 19;

		/**
		 * @type number
		 */
		private frameMin: number = 0;

		/**
		 * @type number
		 */
		private frame: number = 0;

		/**
		 * @type number[][]
		 */
		private positions: any = [
			[-1, -1],
			[-963, -1],
			[-1, -543],
			[-963, -543],
			[-1, -1085],
			[-963, -1085],
			[-1, -1],
			[-963, -1],
			[-1, -543],
			[-963, -543],
			[-1, -1085],
			[-963, -1085],
			[-1, -1],
			[-963, -1],
			[-1, -543],
			[-963, -543],
			[-1, -1085],
			[-963, -1085],
			[-1, -1],
			[-963, -1],
		];

		/**
		 * @type HTMLImageElement[]
		 */
		private spriteImages: HTMLImageElement[] = [];

		/**
		 * @return void
		 */
		@mounted
		private draw() {
			this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
			this.preloadImages();
			this.drawFrame(this.frame);
		}

		/**
		 * @return void
		 */
		preloadImages() {
			this.spriteImages = [
				App.preloader.getByFuzzyUrl('proto-0') as HTMLImageElement,
				App.preloader.getByFuzzyUrl('proto-1') as HTMLImageElement,
				App.preloader.getByFuzzyUrl('proto-2') as HTMLImageElement,
				App.preloader.getByFuzzyUrl('proto-3') as HTMLImageElement,
			];
		}

		/**
		 * @param number frame
		 * @return void
		 */
		drawFrame(frame: number) {
			const imageIndex = Math.floor(frame / 6);
			const image = this.spriteImages[imageIndex];

			// const xPos = (frame % 2) * -960;
			// const yPos = (Math.floor(frame / 2) % 3) * -540;
			const xPos = this.positions[frame][0];
			const yPos = this.positions[frame][1];

			this.context.clearRect(0, 0, 960, 540);

			// this.context.drawImage(image, xPos, yPos, 1920, 1620);
			this.context.drawImage(image, xPos, yPos);
		}

		/**
		 * mk: If you come across this code, do not replicate.
		 * This is bad work.
		 *
		 * The reason I started doing this is that I was using both
		 * setInterval, Interval.add, etc to iterate through frames,
		 * but every once in a while, it would get stuck and stop
		 * animating. Which doesn't make much sense, but rather than
		 * fight with it, I'm trying this.
		 *
		 * @return void
		 */
		animateIn() {
			let lastDraw = Date.now();
			let interval = 0;

			// Reset frame
			this.frame = this.frameMin;

			/**
			 * @return void
			 */
			const drawNext = () => {
				this.frame = (this.frame + 1) % (this.frameMax + 1);
				this.draw();
			};

			/**
			 * @return void
			 */
			const animate = () => {
				const now = Date.now();
				const elapsed = now - lastDraw;

				// We are ready to draw
				if (elapsed >= this.frameRate) {
					lastDraw = now;
					drawNext();
				}

				// Exit
				if (this.frame === this.frameMax) {
					return;
				}

				// Request animation frame
				requestAnimationFrame(animate.bind(this));
			};

			animate();
		}
	}
