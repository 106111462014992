
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';
	import ViewUiLogo from '@/View/Ui/Logo.vue';

	/**
	 * mk: This class is a bit of a mess. We changed header styles a few times
	 * during crunch time and had to swap out assets / restructure things.
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewHeader extends ViewBase {
		/**
		 * @type HTMLVideoElement
		 */
		@Ref('logoSprites')
		protected logoSprites!: ViewUiLogo;

		/**
		 * @type HTMLVideoElement
		 */
		@Ref('logoVideo')
		protected logoVideo!: HTMLVideoElement;

		/**
		 * @type number
		 */
		protected scrollRate: number = 30;

		/**
		 * @type number
		 */
		protected scrollRatio: number = 0;

		/**
		 * @return void
		 */
		@mounted
		protected animateInAutomatically(): void {
			setTimeout(() => this.animateIn(), 1500);
		}

		/**
		 * @return void
		 */
		@mounted
		protected attachEvents(): void {
			window.addEventListener('scroll', this.Handle_OnScroll.bind(this));
		}

		/**
		 * @return void
		 */
		public animateIn(): void {
			this.animationState = 'in';

			this.logoSprites.animateIn();

			// this.logoVideo.play();
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param Event e
		 * @return void
		 */
		private Handle_OnScroll(e: Event): void {
			this.scrollRatio = window.scrollY / 300;
		}

		// endregion: Event Handlers
	}
