
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewMailchimpForm extends ViewBase {
		// Not implemented
	}
