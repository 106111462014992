import * as THREE from 'three';
import Constants from '@/Common/Constants';

/**
 * @type interface
 */
export interface IStoreState {
	cookies: boolean | null;
	isLoggedIn: boolean;
	token?: string;
	user: any;
}

/**
 * @class State
 * @package Store
 * @project LetsRevolution
 */
const state: IStoreState = {
	cookies: null,
	isLoggedIn: false,
	token: undefined,
	user: undefined,
};

export default state;
