
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewButtonIcon extends ViewBase {
		/**
		 * @return string
		 */
		public get imageUrl(): string {
			let images = require.context('@/Asset/image/icon', false, /\.webp$/);
			return images(`./${this.icon}`);
		}

		/**
		 * e.g. Steam
		 *
		 * @type string
		 */
		@Prop()
		public alt!: string;

		/**
		 * e.g. https://www.steam.com
		 *
		 * @type string
		 */
		@Prop()
		public href!: string;

		/**
		 * e.g. steam.png
		 *
		 * @type string
		 */
		@Prop()
		public icon!: string;

		/**
		 * e.g. medium, large
		 *
		 * @type string
		 */
		@Prop({ default: 'medium' })
		public size!: string;

		/**
		 * e.g. blue, yellow, purple, green
		 *
		 * @type string
		 */
		@Prop()
		public type!: string;
	}
