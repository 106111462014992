
	import { beforeDestroy, created, mounted } from '@/Common/Decorators';
	import { Component, Vue } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import Preloader from '@/Lib/Preloader';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @project LetsRevolution
	 */
	@Component
	export default class App extends ViewBase {
		/**
		 * @type Preloader
		 */
		public static preloader: Preloader = new Preloader();

		/**
		 * @type boolean
		 */
		private loaded: boolean = false;

		/**
		 * @return void
		 */
		@mounted
		public startPreloader(): void {
			const images: string[] = [
				require('@/Asset/image/hero/v4/00.webp'),
				require('@/Asset/image/hero/v4/01.webp'),
				require('@/Asset/image/hero/v4/02.webp'),
				require('@/Asset/image/hero/v4/03.webp'),
				require('@/Asset/image/hero/v4/04.webp'),
				require('@/Asset/image/hero/v4/06.webp'),
				require('@/Asset/image/hero/v4/07.webp'),
				require('@/Asset/image/sprite/logo/proto-0.webp'),
				require('@/Asset/image/sprite/logo/proto-1.webp'),
				require('@/Asset/image/sprite/logo/proto-2.webp'),
				require('@/Asset/image/sprite/logo/proto-3.webp'),
			];

			App.preloader
				.setImages(images)
				.preload()
				.then(() => {
					this.loaded = true;
				});
		}

		/**
		 * @return void
		 */
		@mounted
		public setLoaded(): void {
			// setTimeout(() => this.loaded = true, 1000);
		}
	}
