
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewUiTile extends ViewBase {
		/**
		 * @type string
		 */
		public get flippedUrl(): string {
			let images = require.context('@/Asset/image/tile', false, /\.webp$/);

			console.log(images);

			// return images(`${this.type}_flippedTile_${this.version}.webp`);
			return images('Capitol_flippedTile_a');
		}

		/**
		 * @type string
		 */
		public get unflippedUrl(): string {
			let images = require.context('@/Asset/image/tile', false, /\.webp$/);
			return images(`${this.type}_unflippedTile_${this.version}.webp`);
		}

		/**
		 * @type string
		 */
		@Prop({ default: 'Capitol' })
		public type!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'a' })
		public version!: string;
	}
