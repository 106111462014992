
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewRibbon extends ViewBase {
		/**
		 * @type boolean
		 */
		@Prop()
		public animated!: boolean;

		/**
		 * e.g. small, medium, large
		 *
		 * @type string
		 */
		@Prop()
		public size!: string;

		/**
		 * e.g. blue, yellow, purple, green
		 *
		 * @type string
		 */
		@Prop()
		public type!: string;
	}
