
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewVideoPlayer extends ViewBase {
		@Prop({
			default: () => Constants.LINK_TO_TRAILER,
		})
		public readonly src!: string;
	}
