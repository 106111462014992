import { General } from '../Asset/lang/en/index';

/**
 * @class Lang
 * @package Lib
 * @project Lets Revolution
 */
export default class Lang {
	/**
	 * @param string key
	 * @return string
	 */
	public static get(key: string): string {
		// @ts-ignore
		return General[key];
	}
}
