import { ActionContext } from 'vuex';
import { IStoreState } from './state';

/**
 * @type ActionContext<S, R>
 */
type Context = ActionContext<IStoreState, IStoreState>;

/**
 * @class Actions
 * @package Store
 * @project LetsRevolution
 */
const actions = {
	/**
	 * @param Context context
	 * @return void
	 */
	cookiesAccept(context: Context): void {
		context.commit('cookies', true);
	},

	/**
	 * @param Context context
	 * @return void
	 */
	cookiesDeny(context: Context): void {
		context.commit('cookies', false);
	},

	/**
	 * @param Context context
	 * @return void
	 */
	login(context: Context, authResponse: IAuthResponse): void {
		context.commit('authenticate', authResponse);
	},

	/**
	 * @param Context context
	 * @return void
	 */
	logout(context: Context): void {
		context.commit('authenticate', false);
	},
};

export default actions;
