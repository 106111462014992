
	import { Component } from 'vue-property-decorator';
	import ViewSlide from '@/View/Slide.vue';
	import ViewTraitGrid from '@/View/TraitGrid.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Slide
	 * @project Lets Revolution
	 */
	@Component({
		components: {
			ViewTraitGrid,
		},
	})
	export default class ViewSlideTrait extends ViewSlide {
		// Not implemented
	}
