
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import Interval from '@/Lib/Interval';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewSprite extends ViewBase {
		/**
		 * @type number
		 */
		public currentFrame: number = 0;

		/**
		 * @type number
		 */
		@Prop({ default: 100 })
		public frameMax!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 0 })
		public frameMin!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 24 })
		public frameRate!: number;

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public loop!: boolean;

		/**
		 * @return void
		 */
		public animateIn(): void {
			this.currentFrame = this.frameMin;

			Interval.add(() => {
				this.currentFrame++;

				// Check for loop
				if (this.loop && this.currentFrame >= this.frameMax) {
					this.currentFrame = 0;
				}

				// Exit
				else if (this.currentFrame >= this.frameMax) {
					Interval.remove('animate-in');
					this.$emit('complete');
					this.$emit('animate.in');
				}
			}, this.frameRate, 'animate-in');
		}

		/**
		 * @return void
		 */
		public animateOut(): void {
			Interval.add(() => {
				this.currentFrame--;

				// Exit
				if (this.currentFrame <= this.frameMin) {
					Interval.remove('animate-out');
					this.$emit('complete');
					this.$emit('animate.out');
				}
			}, this.frameRate, 'animate-out');
		}
	}
