
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project Lets Revolution
	 */
	@Component
	export default class ViewSlide extends ViewBase {
		/**
		 * @return string
		 */
		public get imageUrl(): string {
			let images = require.context('@/Asset/image/graphic', false, /\.webp$/);
			return images(`./${this.image}`);
		}

		/**
		 * @type string
		 */
		@Prop({ default: 'Image' })
		public alt!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'Lorem ipsum' })
		public body!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'left' })
		public direction!: string;

		/**
		 * @type string
		 */
		@Prop()
		public image!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'Hello World' })
		public title!: string;
	}
