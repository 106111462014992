
	import { Component, Ref } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project LetsRevolution
	 */
	@Component
	export default class PageHome extends PageBase {
		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickAnchor(e: MouseEvent): Promise<void> {
			e.preventDefault();

			// Go to FAQ
			this.$router.push('/faq');

			// Scroll to top
			window.scrollTo(0, 0);
		}
	}
