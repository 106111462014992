
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';
	import ViewUiTooltip from '@/View/Ui/Tooltip.vue';

	/**
	 * @type interface
	 */
	interface ITrait {
		description: string;
		name: string;
		slug: string;
	}

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Ui
	 * @project LetsRevolution
	 */
	@Component({
		components: {
			ViewUiTooltip,
		},
	})
	export default class ViewUiTrait extends ViewBase {
		/**
		 * @type ITrait
		 */
		public get trait(): ITrait {
			return this.traits.find((trait: ITrait) => trait.slug === this.icon) || this.traits[0];
		}

		/**
		 * @type string
		 */
		@Prop({ default: 'all-clear' })
		public icon!: string;

		/**
		 * @type string
		 */
		@Prop({ default: 'normal' })
		public type!: string;

		/**
		 * @type any
		 */
		public traits: ITrait[] = [
			{
				'description': 'Gold bonus for defeating all enemies on the map',
				'name': 'All Clear!',
				'slug': 'all-clear',
			},
			{
				'description': 'Attack every visible enemy. Cost increases by 1 for each visible enemy.',
				'name': 'Armageddon',
				'slug': 'armageddon',
			},
			{
				'description': 'Attack a hidden tile. Move there if it is empty or an enemy is defeated.',
				'name': 'Backstab',
				'slug': 'backstab',
			},
			{
				'description': 'Hide an enemy on 1 of the 4 adjacent tiles.',
				'name': 'Banish',
				'slug': 'banish',
			},
			{
				'description': 'Attack 1 of the 4 adjacent tiles. Move there if the tile is empty or an enemy is defeated.',
				'name': 'Bash',
				'slug': 'bash',
			},
			{
				'description': 'Hide a line of tiles.',
				'name': 'Blow Dart',
				'slug': 'blow-dart',
			},
			{
				'description': 'Attack 3 nearby tiles.',
				'name': 'Blunderbuss',
				'slug': 'blunderbuss',
			},
			{
				'description': 'Attack a distant tile.',
				'name': 'Bow',
				'slug': 'bow',
			},
			{
				'description': 'Attack an unobstructed enemy in the current row or column.',
				'name': 'Bubblegun',
				'slug': 'bubblegun',
			},
			{
				'description': 'Attack any visible enemy.',
				'name': 'Bubblezooka',
				'slug': 'bubblezooka',
			},
			{
				'description': 'Leap over 1 of the 4 adjacent tiles and hide it. ',
				'name': 'Cartwheel',
				'slug': 'cartwheel',
			},
			{
				'description': 'Move into hidden tiles to build up Charge and gain energy equal to the length of the Charge. ',
				'name': 'Charge!',
				'slug': 'charge',
			},
			{
				'description': 'Refill energy and reveal hidden enemies. The number of enemies revealed increases by 1 each use.',
				'name': 'Dark Energy',
				'slug': 'dark-energy',
			},
			{
				'description': 'Take no damage from stepping on a hidden enemy. The enemy stepped on will start with a partially filled clock.',
				'name': 'Dodge',
				'slug': 'dodge',
			},
			{
				'description': 'Chance of encountering elite enemies.',
				'name': 'Elite Enemies',
				'slug': 'elite-enemies',
			},
			{
				'description': 'Enemy clocks are 25% shorter.',
				'name': 'Faster Enemies',
				'slug': 'faster-enemies',
			},
			{
				'description': 'Attack an unobstructed enemy in the current row or column.',
				'name': 'Fireball',
				'slug': 'fireball',
			},
			{
				'description': 'Move to a distant tile in the current row or column.',
				'name': 'Flying Leap',
				'slug': 'flying-leap',
			},
			{
				'description': 'The turn after defeating an enemy gain energy reduction.',
				'name': 'Follow Up',
				'slug': 'follow-up',
			},
			{
				'description': 'Gain +1 damage bonus each time an enemy is defeated.',
				'name': 'Frenzy',
				'slug': 'frenzy',
			},
			{
				'description': 'Move to a nearby revealed 2 tile, hide the tile and gain hidden.',
				'name': 'Hide',
				'slug': 'hide',
			},
			{
				'description': 'Move 1 tile diagonally (maintains charge chain if the tile is hidden).',
				'name': 'Hop',
				'slug': 'hop',
			},
			{
				'description': 'Each time an enemy is defeated, reveal another if there are any left hidden.',
				'name': 'Hydra',
				'slug': 'hydra',
			},
			{
				'description': 'Roads will display a number showing how many of the 8 surrounding tiles around it are roads.',
				'name': 'Insight',
				'slug': 'insight',
			},
			{
				'description': 'Move to 1 of the 4 adjacent tiles.',
				'name': 'Jeté ',
				'slug': 'jete',
			},
			{
				'description': 'Attack an enemy on 1 of the 4 adjacent tiles and regain 1 heart.',
				'name': 'Leech',
				'slug': 'leech',
			},
			{
				'description': 'Start with 75% the normal max energy for this class.',
				'name': 'Less Energy',
				'slug': 'less-energy',
			},
			{
				'description': 'Target a tile in the current row or column, and attack a 3x3 area of tiles. ',
				'name': 'Lightning',
				'slug': 'lightning',
			},
			{
				'description': '50% chance of receiving 1 extra gold each time an enemy is defeated.',
				'name': 'Loot',
				'slug': 'loot',
			},
			{
				'description': 'Whenever the hero attacks, reveal any hidden enemies in the 8 surrounding tiles.',
				'name': 'Noisy',
				'slug': 'noisy',
			},
			{
				'description': 'Gold bonus for finishing a level with all non-road tiles revealed.',
				'name': 'Omniscience',
				'slug': 'omniscience',
			},
			{
				'description': 'Target 1 enemy on the surrounding 8 tiles that is about to attack. Reset their clock and gain 5 turns of Slip Time.',
				'name': 'Parry',
				'slug': 'parry',
			},
			{
				'description': 'Attack all 8 surrounding tiles.',
				'name': 'Roundhouse',
				'slug': 'roundhouse',
			},
			{
				'description': 'Perception magic costs less energy.',
				'name': 'School of Perception',
				'slug': 'school-of-perception',
			},
			{
				'description': 'Power magic costs less energy.',
				'name': 'School of Power',
				'slug': 'school-of-power',
			},
			{
				'description': 'Attack a random visible enemy.',
				'name': 'Sidekick',
				'slug': 'sidekick',
			},
			{
				'description': 'Once per level mark the tile of a hidden enemy.',
				'name': 'Sixth Sense',
				'slug': 'sixth-sense',
			},
			{
				'description': 'Throw a bomb to hide an area of tiles within range. Targeting the hero will grant hidden.',
				'name': 'Smoke Bomb',
				'slug': 'smoke-bomb',
			},
			{
				'description': 'Once per level move anywhere.',
				'name': 'Sneak',
				'slug': 'sneak',
			},
			{
				'description': 'Reveal a tile from a distance.',
				'name': 'Snoop',
				'slug': 'snoop',
			},
			{
				'description': 'Leap over the 8 surrounding tiles and attack any enemy the hero lands on.',
				'name': 'Stomp',
				'slug': 'stomp',
			},
			{
				'description': 'Reveals a hidden enemy every 4 times normal movement reveals a hidden tile.',
				'name': 'Suspicion',
				'slug': 'suspicion',
			},
			{
				'description': 'Move to a distant Tile within range.',
				'name': 'Teleport',
				'slug': 'teleport',
			},
			{
				'description': 'Attack diagonally.',
				'name': 'Throwing Knife',
				'slug': 'throwing-knife',
			},
			{
				'description': 'Attack the upper left or right corner of the 8 surrounding tiles. Move there if it is empty or an enemy is defeated.',
				'name': 'Uppercut',
				'slug': 'uppercut',
			}
		]
	}
