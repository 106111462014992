import Constants from '@/Common/Constants';

export const General = {
	button_accept_text: '<span>Accept</span>',
	button_contentcreators_text: '<span class="xs-hide">Content </span><span>Creators</span>',
	button_discord_text: '<span>Discord</span>',
	button_faq_text: '<span class="xs-hide">Support &amp;&nbsp;</span><span>FAQ</span>',
	button_subscribe_text: '<span>Subscribe</span>',
	button_wishlist_text: '<span class="xs-hide">Wishlist on&nbsp;</span><span>Steam</span>',
	copy_arrivingonconsoles: 'Arriving on consoles April 11th!',
	copy_buynowon: 'Available Now On',
	faq: [
		{
			body: `
			It's available now on <a href="${Constants.LINK_TO_STEAM}" target="_blank">Steam</a> and the <a href="${Constants.LINK_TO_HUMBLE}" target="_blank">Humble Games Store</a>.
			`,
			header: `Where is the game available?`,
		},
		{
			body: `
			Let’s! Revolution! is coming to consoles on April 11th 2024!<br /><br />
			It will be available on the
			<a href="${Constants.LINK_TO_XBOX}" target="_blank">Microsoft Xbox</a> (One, Series S|X),
			<a href="${Constants.LINK_TO_PLAYSTATION}" target="_blank">Sony Playstation</a> (PS4, PS5), and
			<a href="${Constants.LINK_TO_SWITCH}" target="_blank">Nintendo Switch</a>.
			`,
			header: `Will it be available on any other platforms?`,
		},
		{
			body: `
				Let’s! Revolution! is the first original game <br />
				by
				<a href="https://buck.co" target="_blank">BUCK</a>
				and
				<a href="https://antfood.com" target="_blank">Antfood</a>.
			`,
			header: `Who made this game?`,
		},
		{
			body: `
				View the
				<a href="${Constants.LINK_TO_STEAM}" target="_blank">version history</a>
				to see what's changed.
			`,
			header: `What's new in the latest update?`,
		},
		{
			body: `
			Yes, you can find it <a href="${Constants.LINK_TO_DISCORD}" target="_blank">here</a>.
			`,
			header: `Is there a Discord channel for the game?`,
		},
		{
			body: `
				Not currently, but we'll announce it if that changes.
			`,
			header: `Any plans for a physical edition?`,
		},
		{
			body: `
				MacOS  11.7.3 or higher<br />
				WindowsXP or higher<br />
				DX10 graphics (shader model 4)<br />
				8GB of Memory<br />
				4GB of Disk Space
			`,
			header: `What are the system requirements?`,
		},
		{
			body: `
			The game can be played with just a mouse, or with most PC-compatible gamepads.
			`,
			header: `What controls are supported on the PC?`,
		},
		{
			body: `
				Nope! The game will auto-save as you play.
			`,
			header: `Do I need to manually save my game?`,
		},

		// {
		// 	'body': `
		// 		The game features re-bindable controls,
		// 		and "hold"/"toggle" settings for all actions.
		// 		Cursive text can also be swapped to a more
		// 		legible sans serif font.
		// 	`,
		// 	'header': `What accessibility features does it have?`,
		// },
		{
			body: `
				If you come across a bug while playing, please email
				<a href="mailto:support@letsrevolution.com">support@letsrevolution.com</a>.
				Also let us know which platform you were on and your
				computer’s specs if you can.
			`,
			header: `I found a bug! What should I do?`,
		},
		{
			body: `
				For support issues, e-mail <a href="mailto:support@letsrevolution.com">support@letsrevolution.com</a>.
				<br />
				For media inquiries, e-mail <a href="mailto:noah@stridepr.com">noah@stridepr.com</a>.
			`,
			header: `How can I contact you?`,
		},
		{
			body: `
				Most importantly, thank you for playing the Let’s! Revolution!
				<br />
				We really hope you enjoyed our game!
			`,
			header: ` `,
		},
	],
	faq_header_text: 'Support / FAQ',
	home_header_text: 'Available now on Steam!',
	intro: `
		<h1>Beebom needs saving.<br />Do you have what it takes?</h1>
		<p>Rise up in Let’s! Revolution!, a roguelite puzzle mashup that builds on the classic rules of Minesweeper with procedurally generated mazes and turn-based tactics.</p>
		<p>Embark on a deadly game of hide and seek, flipping tiles to outsmart deadly foes and uncover powerful items and abilities as you chase the rotten King across the enchanting land of Beebom.</p>
		<p>Gear up along the way with over 100 items and abilities for a final showdown with the King himself in Beebom City.</p>
	`,
	navigation_buy: 'BUY',
	navigation_link_discord: 'Discord',
	navigation_link_faq: 'FAQ',
	navigation_link_newsletter: 'Newsletter',
	privacypolicy_header_text: 'Privacy Policy',
	quotes: [
		{
			attribution: `9/10 - Hardcore Gamer`,
			text: `Unique, challenging, deep, fun, and even gleefully bizarre in certain ways, Let's! Revolution! is a truly superb blend of puzzlers.`,
		},
		{
			attribution: `Rock, Paper, Shotgun`,
			text: `I cannot stop playing it, nor do I want to.`,
		},
		{
			attribution: `9/10 - God is a Geek`,
			text: `As well as being a thoughtful and engaging puzzle game, Let’s! Revolution! is also absolutely lovely to look at.`,
		},
		{
			attribution: `Game Spew`,
			text: `It takes the tile-turning strategy of a Minesweeper puzzle and combines it with roguelike action. The result? A game that we just can’t put down.`,
		},
	],
	slideshow: [
		{
			body: 'Say goodbye to tiresome calculations and obscure probabilities. Board-game-inspired mechanics keep the focus on learning abilities and strategic gameplay.',
			header: 'KEEP IT SIMPLE',
			screenshot: 'screenshot-00.webp',
		},
		{
			body: 'From the King and his cronies to the Shopkeepers that help you along the way, you’ll encounter a diverse cast of memorable characters brought to life through expressive animation and cheeky dialogue.',
			header: 'PEOPLE TO MEET',
		},
		{
			body: 'With 5 levels of NG+ to unlock for each class, expert players can expect to have their skills put to the ultimate test.',
			header: 'GO DEEP',
		},
		{
			body: 'Take a break from the challenge with Easy Mode, perfect for more casual players or those who want to overthrow the monarchy at their own pace.',
			header: 'OR CHILL OUT',
			screenshot: 'screenshot-01.webp',
		},
	],
};
