
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewModal extends ViewBase {
		/**
		 * @type boolean
		 */
		protected isOpen: boolean = false;

		/**
		 * @return void
		 */
		public close(): void {
			this.isOpen = false;
		}

		/**
		 * @return void
		 */
		public open(): void {
			this.isOpen = true;
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickClose(e: MouseEvent): Promise<void> {
			e.preventDefault();

			this.close();
		}

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickContent(e: MouseEvent): Promise<void> {
			e.stopPropagation();
		}

		// endregion: Event Handlers
	}
