
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { mounted } from '@/Common/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewFooter extends ViewBase {
		/**
		 * @type string
		 */
		public get year(): string {
			return new Date().getFullYear().toString();
		}
	}
