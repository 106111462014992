
	import { mounted } from '@/Common/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project LetsRevolution
	 */
	@Component
	export default class ViewButtonCompany extends ViewBase {
		/**
		 * @return string
		 */
		public get imageUrl(): string {
			let images = require.context('@/Asset/image/company', false, /\.(svg|webp)$/);
			return images(`./${this.icon}`);
		}

		/**
		 * e.g. BUCK
		 *
		 * @type string
		 */
		@Prop()
		public alt!: string;

		/**
		 * e.g. https://www.buck.co
		 *
		 * @type string
		 */
		@Prop()
		public href!: string;

		/**
		 * e.g. buck.png
		 *
		 * @type string
		 */
		@Prop()
		public icon!: string;

		/**
		 * e.g. blue, yellow, purple, green
		 *
		 * @type string
		 */
		@Prop()
		public type!: string;
	}
