
	import { Component, Ref } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project LetsRevolution
	 */
	@Component
	export default class PageError404 extends PageBase {
		// Not implemented
	}
