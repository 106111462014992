import Vue, { defineAsyncComponent } from 'vue';

export default {
	/**
	 * Install Components
	 */
	install: (app: any, options: any) => {
		// Log
		console.log('Initializing app with', options);

		// Directives (like v-model, v-show)
		// @see https://v3.vuejs.org/guide/custom-directive.html#intro

		// Provide components
		const requireComponent = require.context('../View', true, /[A-Zi]\w+\.(vue|js)$/);

		  // For each matching component filename...
		requireComponent.keys().forEach((filename: string = '') => {
			// Get the component config
			const componentConfig = requireComponent(filename);

			// Get the PascalCase version of the component name
			const componentName = 'View' + filename
				.replace('.vue', '')
				.replace(/[^a-zA-Z0-0-]+/g, '')
				.replace(/(index)$/, '');

			// Register the component globally
			app.component(componentName, componentConfig.default || componentConfig);
		});
	}
};
